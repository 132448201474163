<template>
    <div>
        <div v-if="requestsData.length && !fetchingRequests">
            <div class="d-flex flex-wrap align-center justify-space-between w-100">
                <div class="d-flex align-center">
                    <div v-for="requestType in availableRequestTypes" :key="requestType">
                        <v-checkbox class="mx-4 my-0" v-model="requestsSwitchData[requestType]">
                            <template v-slot:label>
                                <div>
                                    <v-icon class="mr-1" small :color="getMailIconColor(requestType)">mail</v-icon>
                                    <span class="secondary--text caption text-uppercase">{{ invitationAndRequestStatusTypes[requestType] }}</span>
                                </div>
                            </template>
                        </v-checkbox>
                    </div>
                </div>
                <v-spacer />
                <template v-if="requestType === requestTypes.REQUESTS_TO_APPROVE">
                    <v-text-field
                        v-model="requestSearch"
                        autofocus
                        class="ma-0 pa-0"
                        append-icon="search"
                        placeholder="Filter"
                        dense
                        single-line
                        outlined></v-text-field>
                </template>
            </div>
            <v-divider class="mb-5 mt-1"></v-divider>
            <v-data-iterator :items="filteredRequestsList">
                <template v-slot:default="props">
                    <div v-for="(item, index) in props.items" :key="index">
                        <v-divider inset v-if="index > 0" style="opacity: 0.25; border-width: thin" class="mb-2" />
                        <v-list-item two-line>
                            <v-row align="center" justify="space-between">
                                <v-col cols="1">
                                    <v-icon :color="getMailIconColor(getRequestStatus(item))" x-large>mail</v-icon>
                                </v-col>
                                <v-col cols="5">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div v-if="requestType === requestTypes.REQUESTS_TO_APPROVE">
                                                {{ `${item.requestee_given_name} ${item.requestee_family_name}` }}
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <div class="d-flex flex-column">
                                                <div class="caption mt-1" v-if="item.notification_timestamp">
                                                    <span class="font-weight-bold">Sent on:</span>
                                                    {{ item.notification_timestamp | dateTimeToHuman }}
                                                </div>
                                                <div
                                                    class="caption mt-1 mb-0"
                                                    v-if="getRequestStatus(item) === invitationAndRequestStatusTypes.REVOKED && item.revocation_timestamp">
                                                    <span class="font-weight-bold">Revoked on:</span>
                                                    {{ item.revocation_timestamp | dateTimeToHuman }}
                                                </div>
                                                <div
                                                    v-if="
                                                        getRequestStatus(item) === invitationAndRequestStatusTypes.ACCEPTED &&
                                                        invitationOrRequestHasTimestamp(item)
                                                    "
                                                    class="mt-1 caption">
                                                    <div>
                                                        <span class="font-weight-bold">Accepted on:</span>
                                                        {{ item.request_responses[0].timestamp | dateTimeToHuman }}
                                                    </div>
                                                    <div class="mt-1">
                                                        <span class="font-weight-bold">Approved by:</span>
                                                        {{ item.request_responses[0].approver_full_name }} ({{ item.request_responses[0].approver_email }})
                                                    </div>
                                                </div>
                                                <div
                                                    v-else-if="
                                                        getRequestStatus(item) === invitationAndRequestStatusTypes.REJECTED &&
                                                        invitationOrRequestHasTimestamp(item)
                                                    "
                                                    class="mt-1 caption">
                                                    <div>
                                                        <span class="font-weight-bold">Rejected on:</span>
                                                        {{ item.request_responses[0].timestamp | dateTimeToHuman }}
                                                    </div>
                                                    <div class="mt-1">
                                                        <span class="font-weight-bold">Rejected by:</span>
                                                        {{ item.request_responses[0].approver_full_name }} ({{ item.request_responses[0].approver_email }})
                                                    </div>
                                                </div>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="4">
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
                                            <div v-for="(role, index) in item.roles" :key="index" class="d-flex flex-column">
                                                <v-tooltip left max-width="500">
                                                    <template v-slot:activator="{ on }">
                                                        <div v-on="on">
                                                            <ComponentWithStyledBorder>
                                                                <div
                                                                    v-if="role.role_type === invitationAndRequestRoleTypes.INSTANCE_ROLE"
                                                                    class="d-flex align-center">
                                                                    <v-icon class="mr-1 ml-1" v-if="role.Name === 'N/A'">help_outline</v-icon>
                                                                    <v-icon v-else-if="role.Name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">
                                                                        visibility
                                                                    </v-icon>
                                                                    <v-icon v-else-if="role.Name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                                                    <span v-if="role.Instance">
                                                                        <span v-if="role.Space && role.Space !== 'N/A'">{{ role.Space }}/</span>
                                                                        {{ role.Instance }}
                                                                    </span>
                                                                    <span v-else>Target deleted</span>
                                                                </div>
                                                                <div
                                                                    v-else-if="role.role_type === invitationAndRequestRoleTypes.SPACE_ROLE"
                                                                    class="d-flex align-center">
                                                                    <v-icon v-if="role.Name === 'N/A'" class="mr-1 ml-1">help_outline</v-icon>
                                                                    <v-icon v-else class="mr-1 ml-1">security</v-icon>
                                                                    {{ role.Space }}
                                                                </div>
                                                                <div
                                                                    v-else-if="role.role_type === invitationAndRequestRoleTypes.ORG_ROLE"
                                                                    class="d-flex align-center">
                                                                    <v-icon v-if="role.Name === 'N/A'" class="mr-1 ml-1">help_outline</v-icon>
                                                                    <v-icon v-else class="mr-1 ml-1">apartment</v-icon>
                                                                    {{ role.Organization }}
                                                                </div>
                                                            </ComponentWithStyledBorder>
                                                        </div>
                                                    </template>
                                                    <div class="d-flex flex-column pa-2">
                                                        <div>
                                                            <span class="font-weight-bold">Role:</span>
                                                            <span v-if="role.role_type === invitationAndRequestRoleTypes.INSTANCE_ROLE">
                                                                <span v-if="role.Name !== 'N/A'">
                                                                    Instance
                                                                    <span class="text-lowercase">{{ role.Name }}</span>
                                                                </span>
                                                                <span v-else>{{ role.Name }}</span>
                                                            </span>
                                                            <span v-if="role.role_type === invitationAndRequestRoleTypes.SPACE_ROLE">Space administrator</span>
                                                            <span v-if="role.role_type === invitationAndRequestRoleTypes.ORG_ROLE">
                                                                Organization
                                                                <span class="text-lowercase">{{ role.Name }}</span>
                                                            </span>
                                                        </div>
                                                        <div v-if="role.role_type === invitationAndRequestRoleTypes.INSTANCE_ROLE">
                                                            <span class="font-weight-bold">Space:</span>
                                                            {{ role.Space }}
                                                        </div>
                                                        <div v-if="role.role_type === invitationAndRequestRoleTypes.INSTANCE_ROLE">
                                                            <span class="font-weight-bold">Instance:</span>
                                                            {{ role.Instance }}
                                                        </div>
                                                        <div>
                                                            <span class="font-weight-bold">Note:</span>
                                                            <span v-if="role.Name === 'N/A'">The target object has been deleted</span>
                                                            <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE">
                                                                This role will overwrite the current user role, even if the current role is more powerful that
                                                                this one.
                                                            </span>
                                                            <span v-else-if="role.method === roleUpdateOptions.OVERWRITE_CURRENT_ROLE_IF_LOWER">
                                                                This role will overwrite the current role only if the current role is less powerful than this
                                                                one.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </v-tooltip>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-col>
                                <v-col cols="1">
                                    <div
                                        v-if="
                                            getRequestStatus(item) === invitationAndRequestStatusTypes.PENDING ||
                                            getRequestStatus(item) === invitationAndRequestStatusTypes.CREATED
                                        "
                                        class="d-flex align-center">
                                        <InvitationActionsDialog
                                            v-if="requestType === requestTypes.OWN_REQUESTS"
                                            :successFetchAction="
                                                () => {
                                                    $store.dispatch('userStore/fetchUserOwnRequests')
                                                }
                                            "
                                            :invitationInfo="item"
                                            :actionType="actionTypes.REVOKE_REQUEST"></InvitationActionsDialog>
                                        <InvitationActionsDialog
                                            v-if="requestType === requestTypes.REQUESTS_TO_APPROVE"
                                            :successFetchAction="
                                                () => {
                                                    $store.dispatch('userStore/fetchUserToApproveRequests')
                                                }
                                            "
                                            :invitationInfo="item"
                                            :actionType="actionTypes.REJECT_REQUEST"></InvitationActionsDialog>
                                        <InvitationActionsDialog
                                            v-if="requestType === requestTypes.REQUESTS_TO_APPROVE"
                                            :successFetchAction="
                                                () => {
                                                    $store.dispatch('userStore/fetchUserToApproveRequests')
                                                }
                                            "
                                            :invitationInfo="item"
                                            :actionType="actionTypes.APPROVE_REQUEST"></InvitationActionsDialog>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </div>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning" text class="mt-3">Your search for found no results.</v-alert>
                </template>
            </v-data-iterator>
        </div>
        <v-alert v-else-if="!fetchingRequests && !requestsData.length && requestType === requestTypes.OWN_REQUESTS" type="info" prominent text>
            <div class="d-flex flex-column">
                <span class="font-weight-bold">No submitted requests</span>
                <span>You haven't submitted any request so far.</span>
            </div>
        </v-alert>
        <v-alert v-else-if="!fetchingRequests && !requestsData.length && requestType === requestTypes.REQUESTS_TO_APPROVE" type="info" prominent text>
            <div class="d-flex flex-column">
                <span class="font-weight-bold">No requests to approve</span>
                <span>You haven't received any request from other users so far.</span>
            </div>
        </v-alert>
        <div v-else-if="fetchingRequests">
            <div
                v-for="item in [
                    { id: 'c1', opacity: 1 },
                    { id: 'c2', opacity: 0.75 },
                    { id: 'c3', opacity: 0.5 }
                ]"
                :key="item.id"
                class="my-6">
                <div :style="{ opacity: item.opacity }">
                    <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { orderBy } from 'lodash'
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
const InvitationActionsDialog = () => import('@/modules/invitations/components/InvitationsActionsDialog')
const ComponentWithStyledBorder = () => import('@/components/ComponentWithStyledBorder')

export default {
    mixins: [invitationsAndRequests, enumsData],
    props: {
        requestsData: Array,
        fetchingRequests: Boolean,
        requestType: String
    },
    components: {
        InvitationActionsDialog,
        ComponentWithStyledBorder
    },
    data() {
        return {
            requestsSwitchData: {
                ACCEPTED: true,
                PENDING: true,
                REJECTED: true,
                REVOKED: true,
                EXPIRED: true,
                BROKEN: true,
                CREATED: true
            },
            requestSearch: '',
            roleUpdateOptions: {
                OVERWRITE_CURRENT_ROLE: 0,
                OVERWRITE_CURRENT_ROLE_IF_LOWER: 1
            }
        }
    },
    methods: {
        getRequestStatus: function (itemData) {
            if (itemData.request_responses.length && itemData.request_responses[0].response === this.invitationAndRequestStatusTypes.ACCEPTED) {
                return this.invitationAndRequestStatusTypes.ACCEPTED
            } else if (itemData.request_responses.length && itemData.request_responses[0].response === this.invitationAndRequestStatusTypes.REJECTED) {
                return this.invitationAndRequestStatusTypes.REJECTED
            } else if (itemData.status_name === this.invitationAndRequestStatusTypes.REVOKED) {
                return this.invitationAndRequestStatusTypes.REVOKED
            } else if (itemData.status_name === this.invitationAndRequestStatusTypes.SENT && itemData.valid === 'False' && !itemData.request_responses.length) {
                return this.invitationAndRequestStatusTypes.EXPIRED
            } else if (itemData.status_name === this.invitationAndRequestStatusTypes.SENT && itemData.valid === 'True' && !itemData.request_responses.length) {
                return this.invitationAndRequestStatusTypes.PENDING
            } else if (
                itemData.status_name === this.invitationAndRequestStatusTypes.CREATED &&
                itemData.valid === 'True' &&
                !itemData.request_responses.length
            ) {
                return this.invitationAndRequestStatusTypes.CREATED
            } else {
                return this.invitationAndRequestStatusTypes.BROKEN
            }
        }
    },
    computed: {
        ...mapState('userStore', ['userRequests', 'fetchingUserOwnRequests', 'fetchingUserToApproveRequests']),
        ...mapState(['userOrgs']),
        availableRequestTypes() {
            const requests = []
            if (this.requestsData && this.requestsData.length) {
                this.requestsData.forEach(request => {
                    const type = this.getRequestStatus(request)
                    if (!requests.includes(type)) {
                        requests.push(type)
                    }
                })
                return requests
            }
            return []
        },
        displayedRequestTypes: function () {
            const filteredRequests = Object.values(this.invitationAndRequestStatusTypes).filter(
                value => this.availableRequestTypes.includes(value) && this.requestsSwitchData[value]
            )
            return filteredRequests
        },
        filteredRequestsList: function () {
            let requests
            requests = this.requestsData.filter(request => this.displayedRequestTypes.includes(this.getRequestStatus(request)))
            if (this.requestSearch) {
                requests = requests.filter(
                    request =>
                        request.requestee_email.toLowerCase().includes(this.requestSearch.toLowerCase()) ||
                        request.requestee_family_name.toLowerCase().includes(this.requestSearch.toLowerCase()) ||
                        request.requestee_given_name.toLowerCase().includes(this.requestSearch.toLowerCase())
                )
            }
            return orderBy(requests, ['invid'], ['desc'])
        }
    },
    watch: {
        availableRequestTypes: function (nextVal) {
            if (nextVal.length && nextVal.includes(this.invitationAndRequestStatusTypes.PENDING)) {
                this.requestsSwitchData = {
                    ACCEPTED: false,
                    PENDING: true,
                    REJECTED: false,
                    REVOKED: false,
                    EXPIRED: false,
                    BROKEN: false,
                    CREATED: false
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('userStore/fetchUserOwnRequests')
        this.$store.dispatch('userStore/fetchUserToApproveRequests')
    }
}
</script>
